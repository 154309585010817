<template>
  <information-app-modal
    v-model="model"
    button-title="Delete"
    @close="model = false"
    @confirm="confirm"
    icon="warningTriangle"
    shouldDisplayCloseButton
    class="delete-payment-method-modal-wrapper"
  >
    <h1 class="title">{{ $t('DeletePaymentMethodDialog.TitleText').value }}</h1>
    <p class="description">
      {{ $t('DeletePaymentMethodDialog.DeleteDescriptionText').value }}
    </p>

    <template #footer class="button-group">
      <AppButton :loading="confirming" class="primary-cta" @click="confirm">
        {{ $t('DeletePaymentMethodDialog.DeleteButtonText').value }}
      </AppButton>
      <AppButton theme="secondary" class="secondary-cta" @click="model = false">
        {{ $t('DeletePaymentMethodDialog.CancelButtonText').value }}
      </AppButton>
    </template>
  </information-app-modal>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import { PAYMENT_ERRORS } from '@galileo/constants/payments/payment.const'

import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYMENT_CARD_STATUS,
  SEGMENT_PAYMENT_METHOD_TYPES,
} from '@galileo/constants/segmentAnalytics'

import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import {
  useVModel,
  AppDialog,
  AppDialogHeader,
  AppDialogFooter,
  AppButton,
  useAlert,
} from '@oen.web.vue2/ui'

import { useAnalyticsStore, useI18nStore, usePaymentsStore, useAppStore } from '@galileo/stores'

export default {
  name: 'DeletePaymentMethodDialog',
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
    InformationAppModal,
    useAlert,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isQuickTransfer: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const confirming = ref(false)
    const paymentsStore = usePaymentsStore()

    const { add: showSnackAlert } = useAlert()

    const selectedPaymentMethod = paymentsStore.selectedPaymentMethod

    const appStore = useAppStore()

    const confirm = async () => {
      confirming.value = true
      try {
        await paymentsStore.deletePaymentAccount(selectedPaymentMethod.id)

        let analyticsPaymentMethod = SEGMENT_PAYMENT_METHOD_TYPES.BANK_ACCOUNT
        if (selectedPaymentMethod.isCard) {
          analyticsPaymentMethod = selectedPaymentMethod.paymentMethod
        }

        let paymentMethodStatus
        if (selectedPaymentMethod.disabled) {
          paymentMethodStatus = SEGMENT_PAYMENT_CARD_STATUS.INVALID
        } else if (selectedPaymentMethod.isExpired) {
          paymentMethodStatus = SEGMENT_PAYMENT_CARD_STATUS.EXPIRED
        } else {
          paymentMethodStatus = SEGMENT_PAYMENT_CARD_STATUS.ACTIVE
        }

        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_DELETED,
          traits: {
            paymentMethod: selectedPaymentMethod.paymentMethod,
            paymentMethodStatus: paymentMethodStatus,
            location: SEGMENT_LOCATIONS.ACCOUNT,
          },
        })

        showSnackAlert($t('PageQuickTransfer.AccountDeletedAlert').value)

        router.toParentRoute()
      } catch (ex) {
        if (ex.message === PAYMENT_ERRORS.CANNOT_DELETE_BANK_ACCOUNT) {
          appStore.messageBoxGenericOk(
            $t('DeletePaymentMethodDialog.BankAccountCantBeDeletedTitle').value,
            $t('DeletePaymentMethodDialog.BankAccountCantBeDeletedDescription').value
          )
        } else {
          appStore.messageBoxGenericError()
        }
        appStore.logException('Exception during delete payment method', ex)
      }
      confirming.value = false
    }

    return {
      model,
      confirm,
      confirming,
      selectedPaymentMethod,
      $t,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
.delete-payment-method-modal-wrapper {
  @screen sm {
    ::v-deep .card {
      .card-header {
        @apply items-start pb-8;
        .card-header-title {
          @apply mt-12;
        }
      }

      .card-content {
        .card-content-block {
          @apply mb-6;

          .description {
            @apply mt-4;
          }
        }
      }

      .card-footer {
        .secondary-cta {
          @apply mt-6 !important;
        }
      }
    }
  }
}

.title {
  @apply type-h3;
}
</style>
